exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-arbitrum-js": () => import("./../../../src/pages/assets/arbitrum.js" /* webpackChunkName: "component---src-pages-assets-arbitrum-js" */),
  "component---src-pages-assets-bnb-js": () => import("./../../../src/pages/assets/bnb.js" /* webpackChunkName: "component---src-pages-assets-bnb-js" */),
  "component---src-pages-assets-dogecoin-js": () => import("./../../../src/pages/assets/dogecoin.js" /* webpackChunkName: "component---src-pages-assets-dogecoin-js" */),
  "component---src-pages-assets-ethereum-js": () => import("./../../../src/pages/assets/ethereum.js" /* webpackChunkName: "component---src-pages-assets-ethereum-js" */),
  "component---src-pages-assets-fantom-js": () => import("./../../../src/pages/assets/fantom.js" /* webpackChunkName: "component---src-pages-assets-fantom-js" */),
  "component---src-pages-assets-flare-js": () => import("./../../../src/pages/assets/flare.js" /* webpackChunkName: "component---src-pages-assets-flare-js" */),
  "component---src-pages-assets-optimism-js": () => import("./../../../src/pages/assets/optimism.js" /* webpackChunkName: "component---src-pages-assets-optimism-js" */),
  "component---src-pages-assets-polygon-js": () => import("./../../../src/pages/assets/polygon.js" /* webpackChunkName: "component---src-pages-assets-polygon-js" */),
  "component---src-pages-assets-songbird-js": () => import("./../../../src/pages/assets/songbird.js" /* webpackChunkName: "component---src-pages-assets-songbird-js" */),
  "component---src-pages-assets-xdc-js": () => import("./../../../src/pages/assets/xdc.js" /* webpackChunkName: "component---src-pages-assets-xdc-js" */),
  "component---src-pages-assets-xrp-js": () => import("./../../../src/pages/assets/xrp.js" /* webpackChunkName: "component---src-pages-assets-xrp-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-media-kit-js": () => import("./../../../src/pages/media-kit.js" /* webpackChunkName: "component---src-pages-media-kit-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-supported-assets-js": () => import("./../../../src/pages/supported-assets.js" /* webpackChunkName: "component---src-pages-supported-assets-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */)
}

